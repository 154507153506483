<template>
<div :v-if="this.show">

  <div v-if="this.stage===0">
    <app-timer-wait v-if="this.media==='app'" :emitter="this.emitAuth" @onInterval="this.checkAuth()"></app-timer-wait>
    <div v-if="this.media==='sms' || this.media==='email'">

      <div class="m-card m-card--editdata mt-3">
        <div class="m-card__form">
          <b-form-group
            :label="this.$t('generic.insert_pin_1')+this.media+this.$t('generic.insert_pin_2')"
            label-for="pin-input"
            class="m-input m-input__icon"
          >
            <b-form-input
              id="pin-input"
              v-model="pinInput"
              type="text"
            ></b-form-input>
          </b-form-group>
          
        <div class="m-card__action">
          <b-button @click="inviaPin" class=" mb-1 mr-1" variant="primary" size="md">{{ $t("generic.invia") }}</b-button>
          <b-button @click="cancel" class=" mb-1" variant="primary" size="md">{{ $t("generic.annulla") }}</b-button>
        </div>          
        </div>
      </div>

    </div>
  </div>

  <b-modal ref="check-fail"
           id="check-fail"
           centered
           modal-class="o-modal"
           content-class="o-modal__content"
           header-class="o-modal__header"
           dialog-class="o-modal__dialog"
           body-class="o-modal__body o-modal__body--response "
           ok-title="ok-title"
           :cancel-disabled="true"
           :hide-footer="true"
           :hide-header-close="true"
           :hide-header="true"
           size="lg"
  >
    <div class="o-modal__body--icon-close">
      <span class="icon-eye"></span>
      <span  class="icon-close text-light"></span>
    </div>
    <p class="o-modal__body--title text-danger">
      <strong >Errore Verifica Pin</strong>
    </p>
    <p>
      Operazione fallita. E'passato troppo tempo dalla richiesta.
    </p>
    <b-button variant="primary" size="" class="text-light text-uppercase mt-3" @click="closeMessaggio()"
    >OK</b-button
    >
  </b-modal>


  <b-modal ref="pin-fail"
           id="pin-fail"
           centered
           modal-class="o-modal"
           content-class="o-modal__content"
           header-class="o-modal__header"
           dialog-class="o-modal__dialog"
           body-class="o-modal__body o-modal__body--response "
           ok-title="ok-title"
           :cancel-disabled="true"
           :hide-footer="true"
           :hide-header-close="true"
           :hide-header="true"
           size="lg"
  >
    <div class="o-modal__body--icon-close">
      <span class="icon-eye"></span>
      <span  class="icon-close text-light"></span>
    </div>
    <p class="o-modal__body--title text-danger">
      <strong >Errore Pin</strong>
    </p>
    <p>
      Il PIN inserito non è corretto.
    </p>
    <b-button variant="primary" size="" class="text-light text-uppercase mt-3" @click="closeMessaggioPin()"
    >OK</b-button
    >
  </b-modal>
</div>



</template>

<script>
import AppTimerWait from "@/components/molecules/AppTimerWait";
import apiAxios from "@/libs/apiAxios";
export default {
  name: "AppAuthPanel",

  components:{AppTimerWait},
  props:{
    service: {
      default: "fatref"
    },
    media: {
      default: "sms"
    },
    show:{
      default: false
    },
    message: {
      default: "Richiesta autorizzazione"
    },
    action: {
      default: ""
    },
    value: {
      default:null
    }

  },
  data(){
    return {
      stage: 0,
      selection: "sms",
      emitAuth:false,
      checking:false,
      otp:null,
      seed:null,
      pinInput:""
    }
  },
  created() {
    this.startAck(this.media);
  },

  methods: {
    cancel:function ()
    {
      this.$emit("fail", {})
    },
    success:function (payload)
    {
      this.$emit("success",payload)
    },
    closeMessaggio(){
      this.$refs['check-fail'].hide();

    },

    closeMessaggioPin(){
      this.$refs['pin-fail'].hide();

    },
    fail:function ()
    {
      this.$refs['check-fail'].show();
      this.checking=false;
      this.pinInput='';
      this.$emit("fail")

    },
    clickAuth:function ()
    {
      this.$set(this,'emitAuth',true);
      this.$set(this,'stage',1);
      this.$set(this,'selection',"auth");
    },

    async startAck(media) {
      const data={
        reason: this.message,
        media:media,
        action: this.action,
        value: this.value
      }
      let otp=null;
      await apiAxios.post("user/queryAck",data)
          .then(response=>{
            otp=response.data.QueryAckResult;
            this.queryCallback(otp);

          })
          .catch(error=>{
            console.log(error);
            otp=null;
          });
      return otp;
    },
    queryCallback:function(data)
    {
      console.log(data);
      this.otp=data.otp;
      this.seed=('seed' in data)?data.seed:null;
      this.seedAck=('seedAck' in data)?data.seedAck:null;
      this.hashAck=('hashAck' in data)?data.hashAck:null;

      if (this.otp==null)
      {
        this.fail;
        return;
      }

    },
    clickSms:function ()
    {
      console.log("Clieck sms");
      this.$set(this,'selection','sms');
      if (this.service!="ack") {
        this.startService('sms');
      }
      else {
        this.startAck('sms');
      }



    },
    checkAuth:function()
    {
      if (this.checking)
        return;
      this.checking=true;
      apiAxios.get("user/otpPoll/"+this.otp)
        .then(response=>{
          const res=response.data.OtpPollResult;
          this.checking=false;
          switch(res.state)
          {
            case 1:
              if (this.service!="ack")
              sessionStorage.setItem("security_token",res.payload.service_token);
              this.success(res.payload);
              break;
            case 0:
              break;
            case -1:
              this.fail();
              break;
          }
        })
        .catch(error=>{
          console.log(error);
          this.checking=false;
          this.fail();
        });

    },
    smsPinCallback:function(success)
    {
      if (success)
      {
        this.checkAuth();
      }
      else {

        this.pinInput="";
        this.fail();
      }
    },
    inviaPin:function()
    {
      var md5 = require('md5');
      const checkPin=md5(this.seedAck+this.pinInput)
      if (checkPin!=this.hashAck)
      {
        this.$refs['pin-fail'].show();
        return;
      }

      const hash=md5(this.seed+this.pinInput);


      const data={
        hash:hash
      };
      apiAxios.post('user/verifyServicePin/'+this.otp,data)
        .then(response=>{
          const res=response.data.VerifyServicePinResult;
          this.smsPinCallback(res);
        })
      .catch(error=>{
        console.log(error);
        this.$set(this,'stage',2);
      });
    }
  }



}
</script>

<style scoped>

</style>
