<template>
  <div>
    <label>Attendere {{wait}}</label>
  </div>
</template>

<script>
export default {
name: "AppTimerWait",
  props:{
    emitter:{
      default:false
    }
  },
  data: function () {
    return {
      wait:"",
      interval:5,
      count:0,
      intervalid1:'',
    };
  },
  mounted() {
    this.todo();
  }
  ,
  beforeDestroy () {

    clearInterval(this.intervalid1)
  },
  methods : {

    todo : function(){
      this.intervalid1 = setInterval(function(){
        if (this.emitter) {


          this.count++;
          if (this.count >= this.interval) {
            this.count = 0;
            this.wait = "";
            console.log("Emitter");
            this.$emit("onInterval");
          }
          this.wait += ".";
        }


      }.bind(this), 1000);
    }
  },
}
</script>

<style scoped>

</style>
