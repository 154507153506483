<template>
      <div class="o-funtions__object" v-if="this.visible">

        <app-circle-icon
          variant="Large"
          :type-button="typeButton"
          :type="isEnabled?'ng':'Hollow'"
          :icon="this.icon + ' ml-2'"
          :link="linkComputed"
        ></app-circle-icon>
        <p class="o-heading o-heading--upper text-center mt-3">
          {{ this.title }}
        </p>
      </div>
</template>

<script>
import AppCircleIcon from "../molecules/AppCircleIcon.vue";

export default {
  components: { AppCircleIcon },
  name: "AppService",
  props: {
    variant: {
      default: "",
    },
    icon: {

      default: "",
    },
    title: {
      default: "",
    },
    link: {
      default: "",
    },
    serviceName: {
      type: String,
      default:""
    },
    anonymState: {
      default:0
    },
    typeButton: {
      default:'false'
    }
  },
  data() {
    return {
      storedService: null
    };
  },
  computed: {
    visible: function() {
      return this.myService!=null || this.anonymState>0
    },
    isEnabled: function() {
      return true;
      /*
      if (this.myService!=null)
      {
        return this.myService.enabled  && this.strong;
      }
      return  this.anonymState>1;
      */
    },
    strong: function ()
    {
      if (this.userdata==null)
        return false;
      if ('role' in this.userdata)
      {
        return this.userdata.role===3;
      }
      return false;
    },
    linkComputed: function ()
    {
      if (this.typeButton==="true")
        return this.link;

      let lnk=this.link;
      if (!this.isEnabled)
      {
        return "";
      }
      let serv=this.myService;
      if (serv==null) {
        if (this.isEnabled)
          return lnk;
        return "";
      }


      if ('token' in serv)
      {
        return lnk+'?token='+serv.token;
      }
      return lnk;
    },
    userdata: function()
    {
      const userdataStr= sessionStorage.getItem("userdata");
      if (userdataStr==null)
        return null;
      const userdata=JSON.parse(userdataStr);
      return userdata;


    },
    myService: function ()  {

      let serviceName=null;
      try
      {
        serviceName=this.serviceName;
      }
      catch ( e)
      {

        return null;
      }

      const userdataStr= sessionStorage.getItem("userdata");
      const userdata=JSON.parse(userdataStr);

      if (userdata==null)
        return null;
      let serv=null;
      if ('services' in userdata)
      {

         serv=userdata.services.find(item => item.name===serviceName);


        if(typeof(serv) === 'undefined' )
        {

          return null;
        }

      }
      return serv;
    }
  },
  mounted() {},
  methods: {
  },
};
</script>
