<template>
  <div id="dashboard">
    <div class="row no-gutters">
      <div class="col-10 offset-1">
        <div class="m-card text-center ">
          <h1 class="o-heading o-heading--xl">{{ $t("dashboard.title") }}</h1>
          <p>
            {{ $t("dashboard.subtitle") }}
          </p>
        </div>
      </div>

      <div class="col-xl-8 offset-xl-2 col-10 offset-1 my-5" v-if="false">
        <div class="alert alert-warning">
          <p class="o-heading text-center">
            <strong>Manutenzione</strong>
          </p>
          <div class="row no-gutters mt-3">
            <div class="col-12">
                  <p>Gentile utente, per un intervento tecnico pianificato, Domenica 17 settembre a partire dalle ore 08.00 fino alle ore 12.00 potranno esserci disservizi sul portale IlMioCDI. Ci scusiamo per il disagio arrecato.
                  </p>
            </div>
          </div>
        </div>
      </div>


      <div class="col-xl-8 offset-xl-2 col-10 offset-1 mt-4" v-if="false">
        <div class="row mb-4">
          <div class=" col-12 mt-2 ">
            <div class="m-card m-card--warning ">
              <p class="o-heading">
                AVVISO
              </p>
              Gentile utente, a causa di un intervento tecnico pianificato, il nostro portale sarà in manutenzione domenica 7 Luglio dalle ore 9.30 alle ore 14. Durante questo periodo, i servizi online saranno temporaneamente non disponibili. Ci scusiamo per il disagio.
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-8 offset-xl-2 col-10 offset-1"  v-if="false">
        <div class="row mb-4">
          <div class=" col-12 mt-2 ">
            <div class="m-card m-card--error ">
              <p class="o-heading">
                <strong>ATTENZIONE</strong>
              </p>
              <div class="row no-gutters mt-3">

                Per un problema tecnico sono momentaneamente sospese le prenotazioni online e telefoniche. Non è inoltre possibile visualizzare e scaricare i propri referti e fatture dall’area ilmiocdi.
                <br>Ci scusiamo per il disagio arrecato.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-8 offset-xl-2 col-10 offset-1">
        <div class="o-funtions">
          <div class="o-funtions__box">
            <app-service
                icon="icon-prenotazioni"
                serviceName="PRENOTING"
                :link="this.apriPrenoting"
                anonym-state="2"
                type-button="true"
                :title="$t('funtions.prenotazione')"
            ></app-service>

            <app-service
                icon="icon-referto"
                link="/singolo_referto"
                serviceName="SINGLE_REFER"
                anonym-state="2"
                :title="$t('funtions.single_ref')"
            ></app-service>
            <app-service
                icon="image-mdl"
                link="/mdl"
                serviceName="MDL"
                anonym-state="2"
                :title="$t('funtions.mdl')"
            ></app-service>



          </div>
          <div class="o-funtions__box">
            <app-service
                icon="icon-referti"
                :link="this.apriReferti"
                type-button="true"
                :title="$t('funtions.all_ref')"
                service-name="REFERTI"
                anonym-state="2"
            ></app-service>

            <app-service
                icon="icon-fatture"
                :link="this.apriFatture"
                type-button="true"
                :title="$t('funtions.invoice')"
                service-name="FATTURE"
                anonym-state="2"
            ></app-service>


            <app-bunner-strong v-if="!strong" class="mt-4"></app-bunner-strong>
          </div>
        </div>
      </div>


      <div class="col-xl-8 offset-xl-2 col-10 offset-1" v-if="(this.payload.bind===1 && this.payload.validation===2 && this.payload.to_pay)">
        <div class="row mb-4">
          <div class=" col-12 mt-2 ">
            <div class="m-card m-card--error ">
              <p class="o-heading">
                <strong>{{ $t("dashboard.invoice_warning_0") }} <u> <b-link @click="this.apriFatture"  class="" style="color:crimson" >{{$t("dashboard.invoice_warning_0_link") }}</b-link></u></strong>
              </p>


            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-8 offset-xl-2 col-10 offset-1" v-if="(this.payload.bind===0 || this.payload.authenticated===false)">
        <div class="row mb-4">
          <div class=" col-12 mt-2 ">
            <div class="m-card m-card--primary ">
              <p class="o-heading">
                <strong>{{ $t("dashboard.warning_0") }}</strong>
              </p>
              <div class="row no-gutters mt-3">
                <div class="col-12 pt-3">
                  <p>
                    {{ $t("dashboard.warning_1") }} 
                    <u><b-link class="text-white" href="/dettagliUtenza?tab=7" >{{$t("loginCredetials.title") }}</b-link></u>

                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-8 offset-xl-2 col-10 offset-1" v-if="payload.bind===2">
        <div class="row mb-4">
          <div class=" col-12 mt-2 ">
            <div class="m-card m-card--error ">
              <p class="o-heading">
                <strong>{{ $t("dashboard.error_0") }}</strong>
              </p>
              <div class="row no-gutters mt-3">
                <div class="col-2 col-md-1">
                  <span class="icon-phone o-heading--lg"></span>
                </div>
                <div class="col-10 col-md-11">
                  <p>
                    {{ $t("dashboard.error_1") }}
                    <strong>{{ $t("dashboard.tel_0") }}</strong
                    >.
                  </p>
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col-2 col-md-1">
                  <span class="icon-time o-heading"></span>
                </div>
                <div class="col-10 col-md-11">
                  <p>
                    {{ $t("dashboard.message_2") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-8 offset-xl-2 col-10 offset-1">
        <div class="row ">
          <div class="col-12 mt-2 col-lg-8 offset-lg-2 mt-lg-0">
            <div class="m-card px-xl-5">
              <p class="o-heading">
                <strong>{{ $t("dashboard.message_3") }}</strong>
              </p>
              <div class="row no-gutters mt-3">
                <div class="col-2 col-md-1">
                  <span class="icon-phone o-heading--lg"></span>
                </div>
                <div class="col-10 col-md-11">
                  <p>
                    {{ $t("dashboard.message_4") }}
                    <strong>{{ $t("dashboard.message_4_1") }}</strong>
                    {{ $t("dashboard.message_4_2") }}
                    <strong>{{ $t("dashboard.tel_1") }}</strong
                    >.
                  </p>
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col-2 col-md-1">
                  <span class="icon-time o-heading"></span>
                </div>
                <div class="col-10 col-md-11">
                  <p>
                    {{ $t("dashboard.message_5") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <b-modal :v-if="this.services.REFERTI!=null"
        id="referto-otp"
        centered
        modal-class="o-modal"
        content-class="o-modal__content"
        header-class="o-modal__header"
        dialog-class="o-modal__dialog"
        body-class="o-modal__body o-modal__body--response "
        ok-title="ok-title"
        :cancel-disabled="false"
        :hide-footer="true"
        :hide-header-close="false"
        :hide-header="false"
        size="lg"
    >
      <div class="o-modal__body--icon-close">
        <span class="icon-lock"></span>
        <span class="icon-close text-light bg-warning"></span>
      </div>
      <p class="o-modal__body--title text-warning">
        <strong> Abilitazione necessaria </strong>
      </p>


      <app-auth-panel :v-if="this.services.REFERTI!=null"
          :authenticator="true"
          service="fatref"
          :show="this.services.REFERTI!=null"
          @onSuccess="routeTo('/referti')"
      ></app-auth-panel>
    </b-modal>

    <b-modal ref="no_prenoting"
      id="no_prenoting"
      centered
      modal-class="o-modal"
      content-class="o-modal__content"
      header-class="o-modal__header"
      dialog-class="o-modal__dialog"
      body-class="o-modal__body o-modal__body--response "
      :cancel-disabled=true
      :hide-footer=true
      size="lg"
    >
      <p class="mt-3">Servizio non disponibile perchè l'utente è minorenne</p>

      <b-button class="mt-3 text-uppercase" variant="primary" @click="closeModal">{{ $t("generic.ok") }}</b-button>
    </b-modal>

  </div>

</template>

<script>
import apiAxios from "@/libs/apiAxios";
import AppBunnerStrong from "@/components/molecules/AppBunnerStrong";
import AppService from "@/components/molecules/AppService";
import router from "@/router";
import AppAuthPanel from "@/components/organisms/AppAuthPanel";


export default {
  name: "dashboard",
  components: {AppAuthPanel, AppService, AppBunnerStrong},
  props: {
    /* services: {
       default: function () {
         return {
           PRENOTING: {
             name: "PRENOTING",
             authentication: "TOKEN",
             token: "55c8ea8e-b5bc-4094-c259-bd705b51243f",
             enabled: true,
             pinAuth: false
           },
           FATTURE: {
             name: "FATTURE",
             authentication: "OTP",
             service: "fatref",
             enabled: false,
             pinAuth: false
           },
           REFERTI: {
             name: "REFERTI",
             authentication: "OTP",
             service: "fatref",
             enabled: false,
             pinAuth: false
           }

         };
       }
     }*/
  },
  data() {
    return {

      services:{
        PRENOTING: null,
        FATTURE: null,
        REFERTI: null
      }
    };
  },
  computed:{
    strong:function()
    {
      let userdata=this.$store.getters["dateUser/UserData"];
      if (userdata==null)
      {
        return false;
      }
      if ('role' in userdata)
      {
        return userdata.role===3;
      }
      return false;
    },
    payload: function ()
    {
      let userdata=this.$store.getters["dateUser/UserData"];
      if (userdata==null)
      {
        userdata={
          services:this.services
        }
      }
      else {


        if (userdata.needPasswordChange)
        {

          router.push('changePassword');
        }
      }

      console.log(userdata);
      let servs=this.services;
      let myServices=userdata.services;
      const keys=Object.keys(myServices);
      for (let i=0;i<keys.length;i++)
      {
        let value=myServices[keys[i]];
        if (value==null)
          continue;
        let fld = value.name;
        servs[fld] = value;
      }
  /*    myServices.map(function (value) {
        let fld = value.name;
        servs[fld] = value;
      });*/
      userdata.services=servs;

      return userdata;
    }
  },
  created() {
    this.prenotingUrl= process.env.VUE_APP_PRENOTING_URL;


   // this.getDataFromApi();
  },
  methods: {
    routeTo:function(url)
    {
      router.push(url);
    },
    apriReferti(){
      // Utente non validato con servizi
      if (this.payload.validation===0 || this.payload.validation===1){
        router.push('/unauthorized');
        return;
      }

      // Utente senza MPS o senza cod auth
      if (this.payload.bind!=1 || this.payload.authenticated===false){
        router.push('/dettagliUtenza?tab=7');
        return;
      }      

      if (this.payload.services.REFERTI==null){
        return;
      }

      if (!this.payload.services.REFERTI.enabled){
        return;
      }

      sessionStorage.removeItem('singolo');
      sessionStorage.removeItem('referti');

      apiAxios.get('/referti/enabled')
          .then(response => {
            if (response.data.IsEnabledResult)
            {
              router.push('/referti')
            }
            else {
              //this.$bvModal.show("referto-otp");
              /*
              if (this.payload.services.REFERTI.pinAuth)
              {
                router.push('confermaAccessoApp?service=fatref&goto=referti');
              }
              else {
                router.push('confermaCodSms?service=fatref&goto=referti');
              }
              */
             router.push('confermaAccessoApp?service=fatref&goto=referti');
            }
          })
          .catch(error=>{
            console.log(error);
          });
    },
    apriFatture(){
      // Utente non validato con servizi
      if (this.payload.validation===0 || this.payload.validation===1){
        router.push('/unauthorized');
        return;
      }

      // Utente senza MPS o senza cod auth
      if (this.payload.bind!=1 || this.payload.authenticated===false){
        router.push('/dettagliUtenza?tab=7');
        return;
      }    

      if (this.payload.services.FATTURE==null){
        return;
      }

      if (!this.payload.services.FATTURE.enabled){
        return;
      }

      sessionStorage.removeItem('singolo');
      sessionStorage.removeItem('referti');

      apiAxios.get('/fatture/enabled')
          .then(response => {
            if (response.data.IsEnabledResult)
            {
              router.push('/fatture')
            }
            else {
              /*
              if (this.payload.services.FATTURE.pinAuth)
              {
                router.push('confermaAccessoApp?service=fatref&goto=fatture');
              }
              else {
                router.push('confermaCodSms?service=fatref&goto=fatture');
              }
              */
             router.push('confermaAccessoApp?service=fatref&goto=fatture');
            }
          })
          .catch(error=>{
            console.log(error);
          });
    },
    apriPrenoting(){
      const serv=this.payload.services.PRENOTING;

      if (serv==null)
      {
        window.location.replace(process.env.VUE_APP_PRENOTING_URL);
        //window.open(process.env.VUE_APP_PRENOTING_URL);
        return;
      }

      if(!serv.enabled){
        this.$bvModal.show("no_prenoting");
        return;
      }      

      if ('token' in serv) {
        window.location.replace(process.env.VUE_APP_PRENOTING_URL + "sso?token=" + serv.token);
        //window.open(process.env.VUE_APP_PRENOTING_URL + "sso?token=" + serv.token);
      }
      else {
        window.location.replace(process.env.VUE_APP_PRENOTING_URL);
        //window.open(process.env.VUE_APP_PRENOTING_URL);
      }
    },
    /*getDataFromApi() {
      this.payload=this.$store.getters["dateUser/UserData"];

      if (this.payload==null)
      {
        this.payload={services:{
            PRENOTING: null,
            FATTURE: null,
            REFERTI: null}};
      }
      console.log(this.payload.services);
      let servs = [] ;
      const ssss=this.payload.services;
      ssss.map(function (value) {
        let fld = value.name;
        servs[fld] = value;
      });
      this.services = servs;

      console.log(servs);

    }*/
    closeModal()
    {
      this.$bvModal.hide("no_prenoting");      
    },    
  }
};
</script>
