<template>
  <div v-if="!isApp" class="o-header">

    <div class="o-header__container pt-3">
      <div class="o-header__logo">
        <a href="/">
          <b-img-lazy src="../../assets/logo-ext.png" alt="CDI"></b-img-lazy>
        </a>
      </div>
      <div class="o-header__inner">
        <router-link to="/guideTutorial" class="text-primary order-3 order-sm-1 mt-2 mt-0 ml-0 d-none d-md-block">{{
            $t("header.assistenza")
        }}</router-link>
        <app-circle-icon variant="Small" type="Hollow" icon="icon-home" link="/" class="order-1 order-sm-2">
        </app-circle-icon>
        <app-button-header :dataName="userData" class="order-2 order-sm-3"></app-button-header>
      </div>
    </div>
    <div class="vld-parent">
      <Loading :active="isLoading" :can-cancel="false" loader="spinner" :height="300" :width="300" color="#074b7d"
        :is-full-page="true" />
    </div>
  </div>
</template>

<script>
import AppButtonHeader from "../molecules/AppButtonHeader.vue";
import AppCircleIcon from "../molecules/AppCircleIcon.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import store from '@/store'

export default {
  components: {
    AppButtonHeader, AppCircleIcon,
    Loading
  },
  name: "AppHeader",
  props: ["userData"],
  computed: {
    isLoading() {
      return store.getters.isLoading;
    },
    isApp() {
      const v=this.$route.query.app;
      if (v==="1")
        return true;
      return false;
    }
  }
};
</script>
