<template>
  <div>
    <span v-if="typeButton === 'false'">
      <router-link
        class="m-circle-icon"
        :to="link"
        v-if="variant === 'Small' && type == 'Hollow'"
      >
        <span :class="[icon]"></span>
      </router-link>

      <router-link
        class="m-circle-icon m-circle-icon--background"
        :to="link"
        v-if="variant === 'Small' && type !== 'Hollow'"
      >
        <span :class="[icon]"></span>
      </router-link>

      <router-link
        class="m-circle-icon m-circle-icon--big"
        :to="link"
        v-if="variant === 'Large' && type == 'Hollow'"
      >
        <span :class="[icon]"></span>
      </router-link>

      <router-link
        class="m-circle-icon m-circle-icon--big m-circle-icon--background"
        :to="link"
        v-if="variant === 'Large' && type !== 'Hollow'"
      >
        <span :class="[icon]"></span>
      </router-link>

      <router-link
        class="m-circle-icon m-circle-icon--medium"
        :to="link"
        v-if="variant === 'medium' && type == 'Hollow'"
      >
        <span :class="[icon]"></span>
      </router-link>

      <router-link
        class="m-circle-icon m-circle-icon--medium m-circle-icon--background"
        :to="link"
        v-if="variant === 'medium' && type !== 'Hollow'"
      >
        <span :class="[icon]"></span>
      </router-link>
    </span>
    <span v-if="typeButton === 'true'">
      <button
        class="m-circle-icon"
        @click="link"
        v-if="variant === 'Small' && type == 'Hollow'"
      >
        <span :class="[icon]"></span>
      </button>

      <button
        class="m-circle-icon m-circle-icon--background"
        @click="link"
        v-if="variant === 'Small' && type !== 'Hollow'"
      >
        <span :class="[icon]"></span>
      </button>

      <button
        class="m-circle-icon m-circle-icon--big"
        @click="link"
        v-if="variant === 'Large' && type == 'Hollow'"
      >
        <span :class="[icon]"></span>
      </button>

      <button
        class="m-circle-icon m-circle-icon--big m-circle-icon--background"
        @click="link"
        v-if="variant === 'Large' && type !== 'Hollow'"
      >
        <span :class="[icon]"></span>
      </button>

      <button
        class="m-circle-icon m-circle-icon--medium"
        @click="link"
        v-if="variant === 'medium' && type == 'Hollow'"
      >
        <span :class="[icon]"></span>
      </button>

      <button
        class="m-circle-icon m-circle-icon--medium m-circle-icon--background"
        @click="link"
        v-if="variant === 'medium' && type !== 'Hollow'"
      >
        <span :class="[icon]"></span>
      </button>
    </span>
  </div>
</template>

<script>
export default {
  name: "AppCircleIcon",

  props: {
    typeButton: {
      default: "false",
    },

    variant: {
      default: "Small",
    },
    icon: {
      default: "icon-codice-referto",
    },
    type: {
      default: "Hollow",
    },
    link: {
      default: "",
    },
  },
  data() {
    return {
      button: this.small,
    };
  },
  mounted() {},
  methods: {
    changeElement() {
      this.button = !this.button;
    },
  },
};
</script>
