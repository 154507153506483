import apiAxios from '../../../libs/apiAxios'
import store from '@/store';

export default {
    async loadUser({ commit, state }) {
        //const user = sessionStorage.getItem('user');
        console.log(state);
        await apiAxios.get(`/user`, { })
            .then(response => {
                console.log(["Dispath",commit]);
                store.dispatch('dateUser/setUserData',response.data.UserResult);
            })
            .catch((error) => console.log(error));
    },
    async getUser({ commit, state }) {
        //const user = sessionStorage.getItem('user');
        console.log(state);
        await apiAxios.get(`/user`, { })
            .then(response => {
                /* console.log(response); */
                commit('SET_USER', response.data.UserResult)
            })
            .catch((error) => console.log(error));
    },
    setUserData({commit},data)
    {
        commit('SET_USER',data)
    },
    resetState({ commit }) {
        commit('RESET_STATE')
    },
};
