<template>

  <router-link
    class="m-button-header m-button-header__high text-light no-login"
    :to="this.payload==null?'/login':'/dettagliUtenza'"
  >

    <span class="m-button-header__icon icon-user2"></span>
    <span class="m-button-header__name" v-if="this.payload!=null"> {{this.payload.fullname }}<br><span  class="m-button-header__miniature">{{$t("header.area") }}</span></span>
      <span class="m-button-header__name" v-if=" this.payload==null" >{{ $t("header.accedi") }}</span>
  </router-link>
</template>

<script>

import store from '@/store'
export default {

  name: "AppButtonHeader",
   props: ["dataName"],
    data() {
      return {

        state: {
          logged: false,
          nome: null,
          cognome: null
        }
      }
    },
    computed: {
      payload: function ()
      {
        console.log("Read userdata");
        return store.getters["dateUser/UserData"]
      }
    },
    created() {
     // this.payload = this.$store.getters["dateUser/UserData"];
      //this.getStatus();
    },

};
</script>
