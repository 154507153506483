<template>
  <div class="m-bunner-strong">
    <div class="m-bunner-strong__container">
      <div class="m-bunner-strong__line"></div>
      <span class="icon-lock"></span>
      <div class="m-bunner-strong__line"></div>
    </div>
    <p class="text-center">
      {{ $t("funtions.message") }}
    </p>
  </div>
</template>

<script>
export default {
  name: "AppBunnerStrong",

  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
