import apiAxios from '../../libs/apiAxios'
import axios from 'axios'
import moment from 'moment'

const state = {
  logged: false,
  loading:false
};

let date = new Date();
date.setHours(0, 0, 0, 0);
const mutations = {
  'SET_LOADING'(state,payload)
  {
    //console.log("SetLoading:"+payload);
    state.loading=payload;
  },
  'SET_LOGGED'(state, payload) {
    console.log('### SET_log', payload);
    state.logged = payload.logged;
    state.token = payload.token;
    state.message = payload.message;
    state.code = payload.code;
    state.username = payload.username;


    sessionStorage.setItem('token', payload.token);
    sessionStorage.setItem('logged', moment(String(new Date())).format('YYYY-MM-DD HH:mm'));
    sessionStorage.setItem('state', payload.logged);
    sessionStorage.setItem('SET_LOGGED', payload.data.SigninResult);
    sessionStorage.setItem('user', payload.username);
    console.log(state);
  },
  'UNSET_LOGGED'(state, payload) {
    console.log('### UNSET_log', payload);


    sessionStorage.removeItem('token');
    sessionStorage.removeItem('logged');
    sessionStorage.removeItem('state');
    sessionStorage.removeItem('SET_LOGGED');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('userdata');
    sessionStorage.removeItem('security_token');
    console.log('STATE clear');
  },
    'REMOVE_SERVICE'(state, payload) {
        console.log('### REMOVE SERVICE', payload);

        sessionStorage.removeItem('security_token');

    }

  /*  'SET_REFRESH'(state, payload) {
     state.logged = true;
     state.token = payload.authToken;
     state.refreshToken = payload.refreshToken;
     sessionStorage.setItem('token', payload.authToken);
     sessionStorage.setItem('refreshToken', payload.refreshToken);
     sessionStorage.setItem('logged', date);
     sessionStorage.setItem('SET_LOGGED', payload.isSuccess);
     console.log(state);
   } */
};

const actions = {
  setLoading({commit},payload){
    commit('SET_LOADING',payload);
  },

  async getUserDataByToken(token,sectoken) {
    console.log(token);
    await axios.get(`${process.env.VUE_APP_API_ENDPOINT}user`,{headers:{'X-AUTH-TOKEN':token}})
        .then(response => {
          const userdata=response.data.UserResult;
          sessionStorage.setItem('token', token);
          sessionStorage.setItem('logged', moment(String(new Date())).format('YYYY-MM-DD HH:mm'));
          sessionStorage.setItem('state', true);
          sessionStorage.setItem('SET_LOGGED', token);
          sessionStorage.setItem('user', userdata.username);
          sessionStorage.setItem('userdata',JSON.stringify(userdata));
          if (sectoken!=null)
          sessionStorage.setItem('security_token',sectoken);
          return true;
        })
        .catch(error => {
          console.log(error);
        })
  },
  async login({ commit }, userObj) {
    //ajax call to login user,

    const user = {
      "username": userObj.username,
      "password": userObj.password,
    }

    await axios.post(`${process.env.VUE_APP_API_ENDPOINT}signin`,
      user)
      .then(response => {
        console.log(response);
        if (response.data.SigninResult === -2) {
          response.code = response.data.token
          response.message = "USER_GROWN"
          response.token = "";
          response.logged = false;
          response.username = "";
          commit('SET_LOGGED', response);
        } else if (response.data.SigninResult === -3) {
          response.code = response.data.SigninResult
          response.message = "USER_BLOCKED"
          response.token = "";
          response.logged = false;
          response.username = "";
          commit('SET_LOGGED', response);
        } else if (response.data.SigninResult === -4) {
          response.code = response.data.SigninResult
          response.message = "WRONG_PASSWORD"
          response.token = "";
          response.logged = false;
          response.username = "";
          commit('SET_LOGGED', response);
        } else if (response.data.SigninResult === -5) {
          response.code = response.data.SigninResult
          response.message = "USER_INACTIVITY"
          response.token = "";
          response.logged = false;
          response.username = "";
          commit('SET_LOGGED', response);
        } else if (response.data.SigninResult === -6) {
          response.code = response.data.SigninResult
          response.message = "USER_FIRST_ACCESS"
          response.token = "";
          response.logged = false;
          response.username = "";
          commit('SET_LOGGED', response.data);
        } else if (response.data.SigninResult === -7) {
          response.code = response.data.SigninResult
          response.message = "USER_DISABLED"
          response.token = "";
          response.logged = false;
          response.username = "";
          commit('SET_LOGGED', response);
        }else if (response.data.SigninResult === -99) {
          response.code = response.data.SigninResult
          response.message = "GENERIC_ERROR"
          response.token = "";
          response.logged = false;
          response.username = "";
          commit('SET_LOGGED', response);
        } else {
          response.code = 0
          response.message = "USER_LOGGED"
          response.token = response.data.SigninResult;
          response.logged = true;
          response.username = userObj.username;
          commit('SET_LOGGED', response);
        }
        return response;
      })
      .catch(error => {
        console.log('### ERROR: actions.login', error)

        return error;
      });
  },
    reset_session({commit})
    {
        commit('dateUser/RESET_STATE');
        commit('UNSET_LOGGED');
    },
    remove_service({commit})
    {
        commit('REMOVE_SERVICE');
    },

  async logout({ commit }) {
    await apiAxios.post(`${process.env.VUE_APP_API_ENDPOINT}signout`)
      .then(response => {
        console.log(["Logout LOG",response]);
        commit('dateUser/RESET_STATE');
        commit('UNSET_LOGGED');
        sessionStorage.clear();
      })
      .catch(error => {
        console.log('### ERROR: actions.OutOut', error)
      })

  }
};

const getters = {

  getState: state => {

    return state;
  },
  isLoading: state => {
    //console.log("GetStateLoading:" +state.loading);
    return state.loading;
  },

  logged: state => {
    return state.logged;
  },
  token: state => {
    return state.token;
  },

  refreshToken: state => {
    return state.refreshToken;
  },
};


export default {

  state,
  mutations,
  actions,
  getters
}
