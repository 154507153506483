
export default {
 install(Vue) {

   Vue.prototype.$isTaxCode = (value) => {
    const regExp = /[a-z]{6}\d{2}[abcdehlmprst]\d{2}[a-z]\d{3}[a-z]/i;
    return regExp.test(value);
   }

   Vue.prototype.$isPhone = (value) => {
           // const regExp = /^(([+]|00)39)?((3[1-6][0-9]))(\d{7})$/g;
           const regExp= /^(\+|\d)[0-9]{7,16}$/g;
           if (value != null  && value!="") {
               if (regExp.test(value) === false) {
                   return false;
               } else {
                   return null;
               }
           } else {
               return null;
           }
 }

   Vue.prototype.$isEmail = (value) => {
    const regExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i;
    if (value != null) {
        if (regExp.test(value) === false) {
          return false;
        } else {
          return null;
        }
      } else {
        return null;
      }
   }

   Vue.prototype.$isPassword = (value) => {
    const regExp = /^(?=.*[A-Za-z]+)(?=.*\d)[A-Za-z\d\\!"£$%&/()=?^*#.:,;-_<>]{8,}$/;
    if (value != null) {
        if (regExp.test(value) === false) {
          return false;
        } else {
          return null;
        }
      } else {
        return null;
      }
   }

}
}
