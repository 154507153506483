import Vue from 'vue'
import VueRouter from 'vue-router'
import dashboard from '../views/dashboard.vue'
import store from '@/store/index.js';
import moment from "moment";

Vue.use(VueRouter)

const invalidateSession = () => {


  store.dispatch("reset_session");
  sessionStorage.removeItem('SET_LOGGED');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('state');
  sessionStorage.removeItem('logged');
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('userdata');

}

/*const ifNotAuthenticated = (to, from, next) => {
  const token = sessionStorage.getItem('token');
  if (token==null) {
    next()
    return
  }
  next('/')
}*/

const swipe_session = (to, from, next) => {
  invalidateSession();
  next();
}

const onlyUnlogged = (to, from, next) => {
  // const SET_LOGGED = sessionStorage.getItem('SET_LOGGED');
  // const token = sessionStorage.getItem('token');
  let uri = window.location.href.split('?');
  if (uri.length == 2) {
    let vars = uri[1].split('&');
    let getVars = {};
    let tmp = '';
    vars.forEach(function (v) {
      tmp = v.split('=');
      if (tmp.length == 2)
        getVars[tmp[0]] = tmp[1]
      else {
        getVars[tmp[0]] = null
      }
    });

    if ('signout' in getVars) {
      //sessionStorage.clear();
      invalidateSession();
      next('/');
      return;
    }
    next();
  }

  const state = sessionStorage.getItem('state');

  if (state === 'true') {
    next('/dettagliUtenza');
    return;
  }
  next();
}

const ifInSession = (to, from, next) => {
  const SET_LOGGED = sessionStorage.getItem('SET_LOGGED');
  const token = sessionStorage.getItem('token');
  const state = sessionStorage.getItem('state');

  if (SET_LOGGED === token && state == 'true') {


    next();


    return
  } else if (SET_LOGGED !== token) {
    store.dispatch('logout', false);
    sessionStorage.clear();
    next({
      path: '/login',
      query: {
        nextUrl: to,
      }
    })
  }
}

const checkPassword = (to, from, next) => {

  const userdataStr = sessionStorage.getItem('userdata');

  if (userdataStr == null) {
    next({
      path: to
    });
    return;
  }
  else {
    const udata = JSON.parse(userdataStr);
    if (udata.needPasswordChange) {
      next({
        path: '/changePassword',
        query: {
          nextUrl: to,
        }
      });
    }
    else {
      next({ path: to });
    }
    return;
  }
}

const ifAuthenticatedOrSingolo = (to, from, next) => {
  if (sessionStorage.getItem('singolo') !== null) {
    next();
    return;
  }
  ifAuthenticated(to, from, next);
}

const ifAuthenticated = (to, from, next) => {

  const myUrl=to.fullPath==null?'':to.fullPath.split('?')[0];
  if (myUrl === '/referti' ||myUrl === "/fattureDaPagare") {
    if (sessionStorage.getItem('referti') != null) {
      next({
        path: to
      });
      return;
    }
  }

  const SET_LOGGED = sessionStorage.getItem('SET_LOGGED');
  const token = sessionStorage.getItem('token');
  const state = sessionStorage.getItem('state');
  const userdataStr = sessionStorage.getItem('userdata');


  const logged = sessionStorage.getItem('logged');
  let logtime = moment(logged, 'YYYY-MM-DD hh:mm');
  if (!logtime.isValid()) {
    invalidateSession();
    sessionStorage.clear()
    next({
      path: '/login',
      query: {
        nextUrl: to,
      }
    })
    return
  }

  var now = moment(new Date()); //todays date
  var duration = moment.duration(now.diff(logtime));
  var minutes = duration.asMinutes();

  if (minutes > 120) {
    invalidateSession();
    next({
      path: '/login',
      query: {
        nextUrl: to,
      }
    })
    return
  }



  if (SET_LOGGED === token && state == 'true') {

    if (userdataStr != null) {
      const userData = JSON.parse(userdataStr);
      if (userData != null) {
        if (!userData.needPasswordChange) {
          next();
          return;
        }
      }
    }

    next({
      path: '/changePassword',
      query: {
        nextUrl: to,
      }
    })
    return
  } else if (SET_LOGGED !== token) {
    store.dispatch('logout', false);
    sessionStorage.clear();
    next({
      path: '/login',
      query: {
        nextUrl: to,
      }
    })
  }

  next({
    path: '/login',
    query: {
      nextUrl: to,
    }
  })
}


const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: dashboard,
    beforeEnter: checkPassword,
    meta: {
      title: 'CDI - Servizi'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
    beforeEnter: onlyUnlogged,
    meta: {
      title: 'CDI - Login'
    }
  },
  {
    path: '/resetMail',
    name: 'resetMail',
    component: () => import('../views/resetMail.vue'),
    beforeEnter: onlyUnlogged,
    meta: {
      title: 'CDI - Reset Mail'
    }
  },
  {
    path: '/riattivaUtente',
    name: 'riattivaUtente',
    component: () => import('../views/riattivaUtente.vue'),
    beforeEnter: onlyUnlogged,
    meta: {
      title: 'CDI - Login'
    }
  },
  {
    path: '/forgotPassword',
    name: 'forgotPassword',
    component: () => import('../views/forgotPassword.vue'),
    beforeEnter: onlyUnlogged,
    meta: {
      title: 'CDI - Login'
    }
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    component: () => import('../views/changePassword.vue'),
    beforeEnter: ifInSession,
    meta: {
      title: 'CDI - Change Password'
    }
  },
  {
    path: '/registrazione',
    name: 'registrazione',
    component: () => import('../views/registrazione.vue'),
    //   beforeEnter: ifNotAuthenticated,
    meta: {
      title: 'CDI - Registrazione'
    }
  },
  {
    path: '/accessToken',
    name: 'accessToken',
    beforeEnter: swipe_session,
    component: () => import('../views/accessToken.vue'),
    meta: {
      title: 'CDI - AccessToken'
    }
  },
  {
    path: '/emailRegistrazione',
    name: 'emailRegistrazione',
    component: () => import('../views/emailRegistrazione.vue'),
    meta: {
      title: 'CDI - Email Registrazione'
    }
  },
  {
    path: '/attivazioneUtenteLink',
    name: 'attivazioneUtenteLink',
    component: () => import('../views/attivazioneUtenteLink.vue'),
    meta: {
      title: 'CDI - Attivazione'
    }
  },
  {
    path: '/fattureDaPagare',
    name: 'fattureDaPagare',
    component: () => import('../views/fattureDaPagare.vue'),
    beforeEnter: ifAuthenticatedOrSingolo,
    meta: {
      title: 'CDI - Fatture Da Pagare'
    }
  },
  {
    path: '/dettagliUtenza',
    name: 'dettagliUtenza',
    component: () => import('../views/dettagliUtenza.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      title: 'CDI - Dettagli utente'
    }
  },
  {
    path: '/aggiungiFamiliare',
    name: 'aggiungiFamiliare',
    component: () => import('../views/aggiungiFamiliare.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      title: 'CDI - Aggiungi familiare'
    }
  },
  {
    path: '/singolo_referto',
    name: 'codiceDownloadReferto',
    component: () => import('../views/codiceDownloadReferto.vue'),
    meta: {
      title: 'CDI - Codice referto'
    }
  },
  {
    path: '/LetturaReferto',
    name: 'LetturaReferto',
    component: () => import('../views/LetturaReferto.vue'),
    meta: {
      title: 'CDI - Lettura referto'
    }
  },
  {
    path: '/confermaAccessoApp',
    name: 'confermaAccessoApp',
    component: () => import('../views/confermaAccessoApp.vue'),
    meta: {
      title: 'CDI - Conferma OTP'
    }
  },
  {
    path: '/confermaCodSms',
    name: 'confermaCodSms',
    component: () => import('../views/confermaCodSms.vue'),
    meta: {
      title: 'CDI - Conferma SMS'
    }
  },
  {
    path: '/referti',
    name: 'referti',
    component: () => import('../views/referti.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      title: 'CDI - Referti '
    }
  },
  {
    path: '/fatture',
    name: 'fatture',
    component: () => import('../views/fatture.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      title: 'CDI - Fatture'
    }
  },
  {
    path: '/guideTutorial',
    name: 'guideTutorial',
    component: () => import('../views/guideTutorial.vue'),
    meta: {
      title: 'CDI - Guide e tutorial'
    }
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import('../views/unauthorized.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      title: 'CDI - Unauthorized'
    }
  },
  {
    path: '/refertiShare',
    name: 'refertiShare',
    component: () => import('../views/refertiShare.vue'),
    beforeEnter: ifAuthenticatedOrSingolo,
    meta: {
      title: 'CDI - Referti share'
    }
  },
  {
    path: '/B2B',
    name: 'temp',
    component: () => import('../views/temp.vue'),
    meta: {
      title: 'CDI - Temp'
    }
  },
  {
    path: '/mdl',
    name: 'mdl-landing',
    beforeEnter: ifAuthenticated,
    component: () => import('../views/mdl-landing.vue'),
    meta: {
      title: 'CDI - MDL'
    }
  },
  {
    path: '/mdl/home',
    name: 'mdl-home',
    beforeEnter: ifAuthenticated,
    component: () => import('../views/mdl-home.vue'),
    meta: {
      title: 'CDI - MDL'
    }
  },
  {
    path: '/mdl/reserve',
    name: 'mdl-reserve',
    beforeEnter: ifAuthenticated,
    component: () => import('../views/mdl-prenota.vue'),
    meta: {
      title: 'CDI - MDL'
    }
  },
  {
    path: '/mdl/enroll-esito',
    name: 'mdl-enroll-esito',
    component: () => import('../views/mdl-esito.vue'),
    meta: {
      title: 'CDI - MDL'
    }
  },
  {
    path: '/mdl/disponibilita',
    name: 'mdl-disponibilita',
    beforeEnter: ifAuthenticated,
    component: () => import('../views/mdl-dispo.vue'),
    meta: {
      title: 'CDI - MDL'
    }
  },  {
    path: '/mdl/concludi-pren',
    name: 'mdl-concludi',
    beforeEnter: ifAuthenticated,
    component: () => import('../views/mdl-concludi.vue'),
    meta: {
      title: 'CDI - MDL'
    }
  },  {
    path: '/mdl/conferma-esito',
    name: 'mdl-conferma-esito',
    beforeEnter: ifAuthenticated,
    component: () => import('../views/mdl-conferma-esito.vue'),
    meta: {
      title: 'CDI - MDL'
    }
  },
  {
    path: '*',
    redirect: '/'
  }
]
// authentication service

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkExactActiveClass: "active"
})

router.beforeEach(function (to, from, next) {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
  next();
});

export default router
