<template>
  <div id="app">
    <app-header></app-header>
    
    <app-skeleton></app-skeleton>
    <app-footer :small="isDashboard"></app-footer>
  </div>
</template>

<script>

import AppSkeleton from "@/components/layout/AppSkeleton.vue";
import AppHeader from "./components/layout/AppHeader.vue";
import AppFooter from "./components/layout/AppFooter.vue";

export default {
  name: "App",
  components: {
    AppSkeleton,
    AppHeader,
    AppFooter,
  },

   data() {
      return { 
        payload: {
        
        },

        login: false
      }
    },

  computed: {
 
    isDashboard() {
      if (this.$route === "/") {
        return false;
      } else {
        return true;
      }
    },
  },

  beforeMount() {
    window.addEventListener("load", this.onLoad);
    window.addEventListener("beforeunload", this.onUnload);
  },
  beforeDestroy() {
    window.removeEventListener("load", this.onLoad);
    window.removeEventListener("beforeunload", this.onUnload);
  },
  methods: {
    onLoad() {

    },

    onUnload() {


    }
  }
 /*   created() {
      this.userData();
    },
    methods: {
    ...mapActions(["dateUser/getUser"]),
    
       async userData() {
            try {
              await this.$store.dispatch("dateUser/getUser").then(() => {
                this.payload = this.$store.getters["dateUser/AnagraficaUtenteSemplificata"];
                console.log(this.payload);
                return this.payload;
              });
            } catch (e) {
              console.log({ message: "Some error getUser" });
            }       
        },
    } */

    /* watch:{
      $route (to, from){
        console.log(to);
        console.log(from);
      }
    }  */
};
</script>
