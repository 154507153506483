export default {
  AnagraficaUtenteSemplificata() {
    return this.UserData();// state.user.AnagraficaUtente;
  },

  AnagraficaUtenteCompleta(state) {
    return state.user;
  },

  UserData(state) {
    let us=sessionStorage.getItem("userdata");
    state.user=us==null?null:JSON.parse(us);
    return state.user;

  }
};
