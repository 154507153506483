export default {
    SET_USER(state, data) {
        state.user = data;
        if (process.env.VUE_APP_DEBUG == "true") {
            console.log(state.user);
        }
        sessionStorage.setItem('userdata', JSON.stringify(data));
        return state.user;
    },
    RESET_STATE(state) {
        state.user = null;
        sessionStorage.removeItem('userdata');
    }
};
