<template>
  <div class="app-skeleton">
    <main class="o-main">
      <router-view />
    </main>
  </div>
</template>

<script>
export default {
  name: "AppSkeleton",
  components: {},


};
</script>
