import Vue from "vue";
import Vuex from "vuex";
import i18n from "@/i18n";
import App from "./App.vue";
import router from "./router";

import store from "./store";

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import dateCheckType from "./services/dateCheckType.js";

import "./styles/_global.scss";

Vue.use(require("vue-moment"));
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(dateCheckType);

Vue.filter("toCurrency", function (value) {
	if (typeof value !== "number") {
		return value;
	}
	var formatter = new Intl.NumberFormat("it-IT", {
		style: "currency",
		currency: "EUR",
	});
	return formatter.format(value);
});

Vue.config.productionTip = false;
Vue.use(Vuex);
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')