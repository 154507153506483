import Vue     from 'vue'
import VueI18n from 'vue-i18n'

import italian from '@/i18n/italian.json'
import english from '@/i18n/english.json'

Vue.use(VueI18n)

export default new VueI18n({
  //TODO: scommentare la riga per attivare locale da lingua del browser
  //locale: navigator.language.split('-')[0],
  locale: "it",
  fallbackLocale: 'it',
  messages: {
    'it': italian,
    'en': english
  }
})
