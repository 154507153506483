/* import axios from 'axios'

const apiAxios = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  headers: {
    "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjEiLCJuYmYiOjE2MTMzOTEwMDMsImV4cCI6MTYxMzM5MTkwMywiaWF0IjoxNjEzMzkxMDAzfQ.0y0EmSauGKzpixpLBOPF9w90Py_76J0bC5SVn02Ooeg"
    }
})

export default apiAxios */
import axios from 'axios'
import store from '@/store'
import router from "@/router";
/* import router from '@/router' */


const apiAxios = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT
})

apiAxios.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('token');
    const service_token = sessionStorage.getItem('security_token');
    if (token && service_token) {

      /* config.headers.authorization = `${token}`; */
      config.headers = {
        "X-AUTH-TOKEN": `${token}`,
        "X-SERVICE-TOKEN": `${service_token}`
      };
    }
    else {
      if (token)
      {
        config.headers = {
          "X-AUTH-TOKEN": `${token}`
        };
      }
    }
      store.dispatch("setLoading",true);
    return config;
  },
  (error) => {
      store.dispatch("setLoading",false);
      console.log(["apiAxiosRequest",error]);
      Promise.reject(error)},
);


apiAxios.interceptors.response.use(function (response) {

    store.dispatch("setLoading",false);
  return response;
}, function (error) {
    store.dispatch("setLoading",false);
  const {
    response: { status }
  } = error
  console.log(status)
  //Controllo 401 e 403 per X-AUTH-TOKEN non valido
    if (status === 401  ||status === 403||status === 404) {

           store.dispatch("reset_session");


           router.push("/login");
          /* document.location.reload(true); */

          this.reset()


    }
    //Controllo 405 metodo non permesso da X-SERVICE-TOKEN
    if (status === 405) {
        // if (true) {
            //Torno a dashboard
            this.$store.dispatch("remove_service");
            router.push("/?err=service_fail");

       // }
    }
  return Promise.reject(error);
});



export default apiAxios
